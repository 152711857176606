import { render, staticRenderFns } from "./Predicciones.vue?vue&type=template&id=18c2c663&scoped=true&"
import script from "./Predicciones.vue?vue&type=script&lang=js&"
export * from "./Predicciones.vue?vue&type=script&lang=js&"
import style0 from "./Predicciones.vue?vue&type=style&index=0&id=18c2c663&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c2c663",
  null
  
)

export default component.exports